import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"
import Title from "../components/Title"
import Image from "gatsby-image"

const ComponentName = ({ data }) => {
  const { title, description, image, publications, collaborator } = data.project

  const sortedPublication = publications.sort(
    (a, b) => parseInt(b.publicationDate) - parseInt(a.publicationDate)
  )

  return (
    <Layout>
      <SEO title={title} description={description} />
      <section className="blog-template">
        <div className="section-center">
          <Title title={title} />
          <p>
            <ReactMarkdown source={description} />
          </p>
          <h3>Collaborators</h3>
          {collaborator.map(collab => {
            return (
              <div key={collab.id}>
                {collab.name}
                {collab.profilePicture && collab.profilePicture.fluid && (
                  <Image
                    style={{ width: "35%" }}
                    fluid={collab.profilePicture.fluid}
                    className=""
                  />
                )}
                <br></br>
              </div>
            )
          })}
          <br></br>
          {sortedPublication && sortedPublication.length > 0 && (
            <h3>Publications</h3>
          )}
          {sortedPublication.map(publication => {
            return (
              <p key={publication.id}>
                {publication.file && publication.file.url ? (
                  <Link to={publication.file.url}>{publication.title}</Link>
                ) : (
                  <b>{publication.title}</b>
                )}
                <br></br>
                {publication.authors}
                <br></br>
                <i>{publication.venue}</i>, {publication.publicationDate}
              </p>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleProject($slug: String) {
    project: datoCmsProject(slug: { eq: $slug }) {
      title
      description
      image {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      publications {
        file {
          url
        }
        title
        authors
        venue
        publicationDate(formatString: "yyyy")
        id
      }
      collaborator {
        name
        id
        description
        profilePicture {
          fluid(maxHeight: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`

export default ComponentName
